<template>
  <b-overlay
    :show="loading"
    spinner-variant="secondary"
    bg-color="#fff"
    opacity="1"
    rounded="sm"
  >
    <b-card class="card-stats" no-body>
      <h2 class="stat-title stat-title-scroll">Order per land van levering</h2>
      <b-card-body>
        <template v-if="data.length">
          <p
            class="my-1 stat-product"
            v-for="(country, index) of data"
            :key="index"
          >
            <b-badge variant="primary" class="mx-2 text-monospace">
              {{ country.orders }}
            </b-badge>
            <router-link
              :id="`order-by-delivery-country-stat-${index}`"
              :to="{
                name: 'order.index',
                query: { delivery_country: country.code },
              }"
            >
              <template v-if="country.code === 'NL'"> 🇳🇱 Nederland </template>
              <template v-else-if="country.code === 'BE'"> 🇧🇪 België </template>
              <template v-else-if="country.code === 'DE'">
                🇩🇪 Duitsland
              </template>
              <template v-else-if="country.code === 'FR'">
                🇫🇷 Frankrijk
              </template>
            </router-link>

            <b-popover
              :target="`order-by-delivery-country-stat-${index}`"
              triggers="hover"
              placement="right"
              boundary="window"
              variant="stat"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div class="stat-value">
                  {{ country.orders }}
                  <span class="stat-value-prev">
                    {{ country.orders_previous }}
                  </span>
                </div>
                <span class="stat-icon ml-4 d-inline-block">
                  <i
                    :class="{
                      'fa-arrow-trend-up text-success':
                        country.orders > country.orders_previous,
                      'fa-arrow-trend-down text-danger':
                        country.orders < country.orders_previous,
                    }"
                    class="far"
                  />
                </span>
              </div>
            </b-popover>
          </p>
        </template>
        <template v-else>
          Er zijn geen orders gevonden voor deze periode
        </template>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: () => {
        return false;
      },
    },

    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>
