<template>
  <div>
    <b-row class="mb-3">
      <b-col class="d-flex justify-content-end">
        <b-button
          small
          variant="light"
          @click="syncWoocommerce"
          v-b-tooltip.hover
          title="De synchronisatie duurt gemiddeld 15-25 seconden"
        >
          <i class="fas fa-sync"></i>
          Orders uit WooCommerce halen
        </b-button>
      </b-col>
    </b-row>

    <b-card header="Orders">
      <b-row cols="5">
        <b-col>
          <b-form-group
            label="Zoeken"
            description="Zoek op ordernummer, klant of product"
          >
            <b-input
              type="search"
              v-model="filters.terms"
              @change="filter"
              placeholder="Zoek een order"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Land van levering">
            <b-form-select v-model="filters.delivery_country" @change="filter">
              <b-form-select-option :value="null" selected>
                Alle landen
              </b-form-select-option>
              <b-form-select-option value="NL">
                🇳🇱 Nederland
              </b-form-select-option>
              <b-form-select-option value="BE">
                🇧🇪 België
              </b-form-select-option>
              <b-form-select-option value="DE">
                🇩🇪 Duitsland
              </b-form-select-option>
              <b-form-select-option value="FR">
                🇫🇷 Frankrijk
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Status">
            <b-form-select v-model="filters.status" @change="filter">
              <b-form-select-option :value="null" selected>
                Alle statussen
              </b-form-select-option>
              <b-form-select-option :value="OrderStatus.SAMPLE_PROCESSING">
                Proefmonster open
              </b-form-select-option>
              <b-form-select-option :value="OrderStatus.SAMPLE_COMPLETED">
                Proefmonster verzonden
              </b-form-select-option>
              <b-form-select-option :value="OrderStatus.CANCELLED">
                Geannuleerd
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            striped
            bordered
            hover
            show-empty
            no-local-sorting
            :items="orders"
            :fields="fields"
            :busy="loading.orders"
            @sort-changed="sort"
            @row-contextmenu="rowContextMenuHandler"
            @row-clicked="$root.rowClickRedirect($event, 'sample-order.show')"
          >
            <template #table-busy>
              <div class="text-center">
                <b-spinner variant="secondary" class="align-middle mr-3" />
                <strong class="align-middle">
                  Proefmonster orders worden geladen
                </strong>
              </div>
            </template>

            <template #empty="">
              <span class="text-center d-block">
                Er zijn geen orders gevonden
              </span>
            </template>

            <template #cell(samples)="row">
              <b-badge
                v-for="(sample, index) of row.item.orderlines[0].samples"
                :key="index"
                class="mr-2 my-1 badge-large"
                variant="primary"
              >
                {{ sample.sku }}
              </b-badge>
            </template>

            <template v-slot:row-details="row">
              <b-card>
                <b-row>
                  <b-col>
                    <div
                      v-for="(sample, index) of row.item.orderlines[0].samples"
                      :key="index"
                      class="my-2"
                    >
                      <b>
                        {{ sample.name }}
                      </b>
                      <div class="d-block">
                        <b-badge variant="dark">
                          {{ sample.sku }}
                        </b-badge>
                        <b-badge
                          class="ml-1"
                          v-if="sample.supplier_name"
                          variant="dark"
                        >
                          {{ sample.supplier_name }}
                        </b-badge>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </template>

            <template #cell(actions)="row">
              <b-button
                class="mr-1"
                variant="primary"
                @click="$root.copyData(row.item)"
                v-b-tooltip.hover.left="`Verzendgegevens kopiëren`"
              >
                <i class="mr-0 far fa-copy" />
              </b-button>

              <b-button
                @click="row.toggleDetails"
                variant="primary"
                v-b-tooltip.hover.left="`Extra informatie`"
              >
                <i
                  class="mr-0 fas"
                  :class="{
                    'fa-chevron-down': row.detailsShowing,
                    'fa-chevron-up': !row.detailsShowing,
                  }"
                />
              </b-button>
            </template>
          </b-table>

          <b-pagination-nav
            use-router
            first-number
            last-number
            v-model="ordersMeta.current_page"
            :link-gen="linkGen"
            :number-of-pages="ordersMeta.last_page"
            @change="getOrders"
          >
            <template #prev-text>
              <i class="far fa-angle-left"></i>
            </template>
            <template #next-text>
              <i class="far fa-angle-right"></i>
            </template>
            <template #ellipsis-text>
              <i class="fal fa-ellipsis-h"></i>
            </template>
          </b-pagination-nav>
        </b-col>
      </b-row>
    </b-card>

    <context-menu
      :id="$route.name"
      :key="$route.fullPath"
      :display="showContextMenu"
      component="order"
      ref="menu"
    />
  </div>
</template>

<script>
import ContextMenu from '@/components/ContextMenu';
import { OrderStatus, OrderStatusString } from '@/enums/Order/Status';
import { OrderChannel } from '@/enums/Order/Channel';

export default {
  components: {
    ContextMenu,
  },

  data() {
    return {
      OrderStatus: OrderStatus,
      OrderChannel: OrderChannel,

      filters: {
        terms: null,
        status: null,
        orderBy: null,
        orderDirection: null,
        delivery_country: null,
      },

      showContextMenu: false,

      fields: [
        {
          key: 'order_number',
          label: 'Ordernummer',
          sortable: true,
          tdClass: 'column-fit-content text-left',
        },
        {
          key: 'status',
          label: 'Status',
          formatter: (value) => {
            return OrderStatusString[value];
          },
          tdClass: 'column-fit-content text-left',
        },
        {
          key: 'customer.billing_formatted_name',
          label: 'Klantnaam',
          sortable: true,
          tdClass: 'column-fit-content text-left',
        },
        { key: 'samples', label: 'Stalen' },
        {
          key: 'created_at',
          label: 'Aangemaakt op',
          sortable: true,
          formatter: (value) => {
            return this.$options.filters.date(new Date(value));
          },
          tdClass: 'column-fit-content text-left',
        },
        { key: 'actions', label: '', tdClass: 'column-fit-content' },
      ],

      orders: [],
      ordersMeta: {},

      loading: {
        orders: false,
      },
    };
  },

  mounted() {
    this.filters = this.$root.setFilters(this.filters);
    this.getOrders();
  },

  methods: {
    sort(ctx) {
      this.filters.orderBy = ctx.sortBy;
      this.filters.orderDirection = ctx.sortDesc ? 'desc' : 'asc';

      this.filter();
    },

    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`;
    },

    filter() {
      this.$root.applyFilters(this.filters);
      this.getOrders();
    },

    getOrders(page = null) {
      this.loading.orders = true;

      let params = {
        ...this.filters,
        samples: true,
        page: page
          ? this.$route.query.page
            ? this.$route.query.page
            : null
          : null,
      };

      this.$http
        .get('orders', { params: params })
        .then((response) => {
          this.orders = response.data.data;
          this.ordersMeta = response.data.meta;
        })
        .then(() => {
          this.loading.orders = false;
        });
    },

    rowContextMenuHandler(item, _, event) {
      this.$refs.menu.open(event, item);
      event.preventDefault();
    },

    syncWoocommerce() {
      this.$http.get('webhook/woocommerce/orders').then(() => {
        this.$root.notify('De factuur is succesvol verstuurd.');
      });
    },
  },
};
</script>
