<template>
  <div>
    <b-row class="mb-3">
      <b-col class="d-flex justify-content-end">
        <b-button
          small
          variant="light"
          @click="syncWoocommerce"
          v-b-tooltip.hover
          title="De synchronisatie duurt gemiddeld 15-25 seconden"
        >
          <i class="fas fa-sync"></i>
          Orders uit WooCommerce halen
        </b-button>
      </b-col>
    </b-row>

    <b-card header="Orders">
      <b-row>
        <b-col>
          <b-form-group>
            <template #label>
              Zoeken
              <i
                class="ml-1 fas fa-info-circle"
                v-b-tooltip.hover.right
                title="Zoek op ordernummer, order opmerking, klant, product of factuurnummer"
              />
            </template>
            <b-input
              type="search"
              v-model="filters.terms"
              @change="filter"
              placeholder="Zoek een order"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Land van levering">
            <b-form-select v-model="filters.delivery_country" @change="filter">
              <b-form-select-option :value="null" selected>
                Alle landen
              </b-form-select-option>
              <b-form-select-option value="NL">
                🇳🇱 Nederland
              </b-form-select-option>
              <b-form-select-option value="BE">
                🇧🇪 België
              </b-form-select-option>
              <b-form-select-option value="DE">
                🇩🇪 Duitsland
              </b-form-select-option>
              <b-form-select-option value="FR">
                🇫🇷 Frankrijk
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Kanaal">
            <b-form-select v-model="filters.channel" @change="filter">
              <b-form-select-option :value="null" selected>
                Alle kanalen
              </b-form-select-option>
              <b-form-select-option :value="OrderChannel.WOOCOMMERCE">
                {{ OrderChannelString[OrderChannel.WOOCOMMERCE] }}
              </b-form-select-option>
              <b-form-select-option :value="OrderChannel.GADERO">
                {{ OrderChannelString[OrderChannel.GADERO] }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Status">
            <b-form-select v-model="filters.status" @change="filter">
              <b-form-select-option :value="null" selected>
                Alle statussen
              </b-form-select-option>
              <b-form-select-option :value="OrderStatus.ON_HOLD">
                {{ OrderStatusString[OrderStatus.ON_HOLD] }}
              </b-form-select-option>
              <b-form-select-option :value="OrderStatus.PROCESSING">
                {{ OrderStatusString[OrderStatus.PROCESSING] }}
              </b-form-select-option>
              <b-form-select-option :value="OrderStatus.SENT">
                {{ OrderStatusString[OrderStatus.SENT] }}
              </b-form-select-option>
              <b-form-select-option :value="OrderStatus.CANCELLED">
                {{ OrderStatusString[OrderStatus.CANCELLED] }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Soort levering">
            <b-form-select v-model="filters.delivery_type" @change="filter">
              <b-form-select-option :value="null" selected>
                Alle soorten levering
              </b-form-select-option>
              <b-form-select-option :value="OrderDeliveryType.DATE">
                {{ OrderDeliveryTypeString[OrderDeliveryType.DATE] }}
              </b-form-select-option>
              <b-form-select-option :value="OrderDeliveryType.ON_CALL">
                {{ OrderDeliveryTypeString[OrderDeliveryType.ON_CALL] }}
              </b-form-select-option>
              <b-form-select-option :value="OrderDeliveryType.PICK_UP">
                {{ OrderDeliveryTypeString[OrderDeliveryType.PICK_UP] }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            striped
            bordered
            hover
            show-empty
            no-local-sorting
            :items="orders"
            :fields="fields"
            :busy="loading.orders"
            @sort-changed="sort"
            @row-contextmenu="rowContextMenuHandler"
            @row-clicked="$root.rowClickRedirect($event, 'order.show')"
          >
            <template #table-busy>
              <div class="text-center">
                <b-spinner
                  variant="secondary"
                  class="align-middle mr-3"
                ></b-spinner>
                <strong class="align-middle">Orders worden geladen</strong>
              </div>
            </template>
            <template #empty="">
              <span class="text-center d-block">
                Er zijn geen orders gevonden
              </span>
            </template>
            <template #cell(delivery_date)="row">
              <span
                class="mr-2"
                v-if="
                  row.item.delivery_type !== OrderDeliveryType.ON_CALL &&
                  row.item.delivery_date
                "
              >
                {{ new Date(row.item.delivery_date) | date('dd/MM/yyyy') }}
              </span>

              <b-badge variant="primary">
                {{ OrderDeliveryTypeString[row.item.delivery_type] }}
              </b-badge>
            </template>
          </b-table>

          <b-pagination-nav
            use-router
            first-number
            last-number
            v-model="ordersMeta.current_page"
            :link-gen="linkGen"
            :number-of-pages="ordersMeta.last_page"
            @change="getOrders"
          >
            <template #prev-text>
              <i class="far fa-angle-left"></i>
            </template>
            <template #next-text>
              <i class="far fa-angle-right"></i>
            </template>
            <template #ellipsis-text>
              <i class="fal fa-ellipsis-h"></i>
            </template>
          </b-pagination-nav>
        </b-col>
      </b-row>
    </b-card>

    <context-menu
      :id="$route.name"
      :key="$route.fullPath"
      :display="showContextMenu"
      component="order"
      ref="menu"
    />
  </div>
</template>

<script>
import ContextMenu from '@/components/ContextMenu';
import { OrderStatus, OrderStatusString } from '@/enums/Order/Status';
import {
  OrderDeliveryType,
  OrderDeliveryTypeString,
} from '@/enums/Order/DeliveryType';
import { OrderChannel, OrderChannelString } from '@/enums/Order/Channel';

export default {
  components: {
    ContextMenu,
  },

  data() {
    return {
      OrderStatus: OrderStatus,
      OrderStatusString: OrderStatusString,
      OrderDeliveryType: OrderDeliveryType,
      OrderDeliveryTypeString: OrderDeliveryTypeString,
      OrderChannel: OrderChannel,
      OrderChannelString: OrderChannelString,

      filters: {
        channel: null,
        status: null,
        terms: null,
        orderBy: null,
        orderDirection: null,
        samples: false,
        delivery_type: null,
        delivery_country: null,
      },

      showContextMenu: false,

      fields: [
        { key: 'order_number', label: 'Ordernummer', sortable: true },
        {
          key: 'status',
          label: 'Status',
          formatter: (value) => {
            return OrderStatusString[value];
          },
        },
        {
          key: 'customer.billing_company',
          label: 'Bedrijfsnaam',
          sortable: true,
          formatter: (value) => {
            return value || '-';
          },
        },
        {
          key: 'customer.billing_formatted_name',
          label: 'Klantnaam',
          sortable: true,
        },
        {
          key: 'invoice.number',
          label: 'Factuur',
          sortable: true,
          formatter: (value) => {
            value = value ? `#${value}` : '-';
            return value;
          },
        },
        {
          key: 'delivery_date',
          label: 'Leverdatum',
          sortable: true,
          formatter: (value) => {
            if (value) {
              value = this.$options.filters.date(new Date(value), 'dd/MM/yyyy');
            } else {
              value = 'n.b.';
            }
            return value;
          },
        },
        {
          key: 'created_at',
          label: 'Aangemaakt op',
          sortable: true,
          formatter: (value) => {
            return this.$options.filters.date(
              new Date(value),
              'dd/MM/yyyy HH:mm:ss'
            );
          },
        },
      ],

      orders: [],
      ordersMeta: {},

      loading: {
        orders: true,
      },
    };
  },

  methods: {
    sort(ctx) {
      this.filters.orderBy = ctx.sortBy;
      this.filters.orderDirection = ctx.sortDesc ? 'desc' : 'asc';

      this.filter();
    },

    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`;
    },

    filter() {
      this.$root.applyFilters(this.filters);
      this.getOrders();
    },

    getOrders(page = null) {
      this.loading.orders = true;

      let params = {
        ...this.filters,
        page: page
          ? this.$route.query.page
            ? this.$route.query.page
            : null
          : null,
      };

      this.$http
        .get('orders', { params: params })
        .then((response) => {
          this.orders = response.data.data;
          this.ordersMeta = response.data.meta;
        })
        .then(() => {
          this.loading.orders = false;
        });
    },

    rowContextMenuHandler(item, _, event) {
      this.$refs.menu.open(event, item);
      event.preventDefault();
    },

    syncWoocommerce() {
      this.$http.get('webhook/woocommerce/orders').then(() => {
        this.$root.notify('Nieuwe orders zijn succesvol toegevoegd.');
      });
    },
  },

  mounted() {
    this.filters = this.$root.setFilters(this.filters);
    this.getOrders();
  },
};
</script>
